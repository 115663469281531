import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'register/:id?',
    component: asyncComponent(() => import('../Register')),
  },
  {
    path: 'claim/:id',
    component: asyncComponent(() => import('../Register')),
  },
  {
    path: 'evaluate',
    component: asyncComponent(() => import('../Evaluate')),
    exact: false,
  },
  {
    path: 'decision',
    component: asyncComponent(() => import('../Decision')),
    exact: false,
  },
  {
    path: 'review',
    component: asyncComponent(() => import('../Review')),
    exact: false,
  },
  {
    path: 'report',
    component: asyncComponent(() => import('../Report')),
  },
  {
    path: 'payment',
    component: asyncComponent(() => import('../Payment')),
  },
  {
    path: 'mailbox',
    component: asyncComponent(() => import('../Mail')),
  },
  {
    path: 'search',
    component: asyncComponent(() => import('../Search')),
  },
  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },
  {
    path: 'authCheck',
    component: asyncComponent(() => import('../AuthCheck')),
  },
  {
    path: 'setting',
    component: asyncComponent(() => import('../Setting')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
