import actions from './actions';

const initState = {
  status:
    localStorage.getItem('isPending') !== null
      ? localStorage.getItem('isPending')
      : false,
};

export default function pendingclaimReducer(state = initState, action) {
  switch (action.type) {
    case actions.STATUSPENDINGACTION_CLAIMS:
      return {
        ...state,
        status: action.payload,
      };
    case actions.SETPENDINGACTION_CLAIMS:
      return {
        ...state,
      };
    default:
      return state;
  }
}
