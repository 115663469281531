import {
  all,
  takeEvery,
  put,
  call,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { setPendingClaimsAlert } from '../../helpers/utility';
import actions from './actions';

export function* setPendingActionOnClaims() {
  yield takeEvery(actions.SETPENDINGACTION_CLAIMS, function*({ payload }) {
    const isPendingClaims = yield setPendingClaimsAlert(payload);
    yield put({
      type: actions.STATUSPENDINGACTION_CLAIMS,
      payload: isPendingClaims,
    });
  });
}

export default function* rootSaga() {
  yield all([fork(setPendingActionOnClaims)]);
}
