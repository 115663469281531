import { all, takeEvery, fork, call } from 'redux-saga/effects';
import actions from './actions';
import SuperFetch from '../../helpers/superFetch';
import notification from '@isomorphic/shared/isomorphic/components/Notification';
export function* filterAction() {
  yield takeEvery(actions.FILTER_ATTRIBUTE, function*() {});
}

export function* sendHTML() {
  yield takeEvery(actions.SENDHTML, function*({ payload }) {
    console.log(payload)
    const result = yield call(
      async payload => {

        return await SuperFetch.post('email/sendhtml', payload).then(response => {
          return response;
        });
      },
    {html:payload});
    console.log(result)
    notification('success', result.statusText)
  });
}


export default function* rootSaga() {
  yield all([
    fork(filterAction),
    fork(sendHTML),
  ]);
}
