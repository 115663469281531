import { jwtConfig } from '../settings';
import axios from 'axios';

const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: localStorage.getItem('id_token') || undefined,
});

const baseAxios = (method, url, data = {}) => {
  return axios({
    method: method,
    url: `${jwtConfig.fetchUrl}${url}`,
    headers: customHeader(),
    data: data
  })
    .then(res => res)
    .catch(error => ({ error: error }));

};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = baseAxios.bind(null, method);
});
export default SuperFetch;
