const actions = {
  INITCLAIMINFO_REQUEST: 'INITCLAIMINFO_REQUEST',
  INITCLAIMINFO_SUCCESS: 'INITCLAIMINFO_SUCCESS',
  GETCLAIMBYPARAM_REQUEST: 'GETCLAIMBYPARAM_REQUEST',
  GETCLAIMBYPARAM_SUCCESS: 'GETCLAIMBYPARAM_SUCCESS',
  UPDATECLAIMINFO_REQUEST: 'UPDATECLAIMINFO_REQUEST',
  CREATECLAIM_SUCCESS: 'CREATECLAIM_SUCCESS',
  GETVISIT_REQUEST: 'GETVISIT_REQUEST',
  CREATEVISIT_REQUEST: 'CREATEVISIT_REQUEST',
  CREATEVISIT_SUCCESS: 'CREATEVISIT_SUCCESS',
  UPDATEVISIT_REQUEST: 'UPDATEVISIT_REQUEST',
  UPDATEVISIT_SUCCESS: 'UPDATEVISIT_SUCCESS',
  DELETEVISIT_REQUEST: 'DELETEVISIT_REQUEST',
  DELETEVISIT_SUCCESS: 'DELETEVISIT_SUCCESS',
  UPDATEREIMBUSEMENTCURRENCY_REQUEST:'UPDATEREIMBUSEMENTCURRENCY_REQUEST',
  UPDATEREIMBUSEMENTCURRENCY_SUCCESS:'UPDATEREIMBUSEMENTCURRENCY_SUCCESS',
  UPDATEBILLINGCURRENCY_REQUEST:'UPDATEBILLINGCURRENCY_REQUEST',
  UPDATEBILLINGINFO_REQUEST:'UPDATEBILLINGINFO_REQUEST',
  UPLOADBILLINGINFOOTHERNAME:'UPLOADBILLINGINFOOTHERNAME',
  INSERTDOCUMENTS_REQUEST:'INSERTDOCUMENTS_REQUEST',
  INSERTDOCUMENTS_SUCCESS:'INSERTDOCUMENTS_SUCCESS',
  RESETCLAIM: 'RESETCLAIM',
  SETTABINDEX_REQUEST:'SETTABINDEX_REQUEST',
  GETALLCLAIMS_REQUEST:'GETALLCLAIMS_REQUEST',
  GETALLCLAIMS_SUCCESS:'GETALLCLAIMS_SUCCESS',
  GETCLAIMTITLEBYID_REQUEST:'GETCLAIMTITLEBYID_REQUEST',
  GETCLAIMTITLEBYID_SUCCESS:'GETCLAIMTITLEBYID_SUCCESS',
  INITVISITSDATABYID_REQUEST:'INITVISITSDATABYID_REQUEST',
  INITVISITSDATABYID_SUCCESS:'INITVISITSDATABYID_SUCCESS',
  SUBMITCLAIMINFO_REQUEST:'SUBMITCLAIMINFO_REQUEST',
  SUBMITCLAIMINFO_SUCCESS:'SUBMITCLAIMINFO_SUCCESS',
  SUBMITDOCINFO_REQUEST:'SUBMITDOCINFO_REQUEST',
  SUBMITDOCINFO_SUCCESS:'SUBMITDOCINFO_SUCCESS',
  initClaim: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.INITCLAIMINFO_REQUEST,
        payload
      });
    };
  },
  getClaimByParam: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GETCLAIMBYPARAM_REQUEST,
        payload
      });
    };
  },
  updateClaim: (payload) => {

    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATECLAIMINFO_REQUEST,
        payload
      });
    };
  },
  updateReimbusementCurrency: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATEREIMBUSEMENTCURRENCY_REQUEST,
        payload
      });
    };
  },
  createVisit:(payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.CREATEVISIT_REQUEST,
        payload
      });
    };
  },
  updateVisit:(payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATEVISIT_REQUEST,
        payload
      });
    };
  },
  deleteVisit:(payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.DELETEVISIT_REQUEST,
        payload
      });
    };
  },
  updateBillingCurrency: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATEBILLINGCURRENCY_REQUEST,
        payload
      });
    };
  },
  updateBillingInfo: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATEBILLINGINFO_REQUEST,
        payload
      });
    };
  },

  uploadBillingInfoOtherName:  (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPLOADBILLINGINFOOTHERNAME,
        payload
      });
    };
  },
  insertDocuments: (payload) => {

    return (dispatch, getState) => {
      dispatch({
        type: actions.INSERTDOCUMENTS_REQUEST,
        payload
      });
    };
  },
  resetClaim: () => {

    return (dispatch, getState) => {
      dispatch({
        type: actions.RESETCLAIM
      });
    };
  },

  setTabIndex: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SETTABINDEX_REQUEST,
        payload
      });
    };
  },
  getClaimTitleById: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.GETCLAIMTITLEBYID_REQUEST,
        payload
      });
    };
  },
  initVisitsDataById: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.INITVISITSDATABYID_REQUEST,
        payload
      });
    };
  },
  getAllClaims: () => ({
    type: actions.GETALLCLAIMS_REQUEST
  }),
  submitClaimInfo: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SUBMITCLAIMINFO_REQUEST,
        payload
      });
    };
  },
  submitDocInfo: (payload) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SUBMITDOCINFO_REQUEST,
        payload
      });
    };
  }
};
export default actions;
