import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';

import Auth from './auth/reducer';
import App from './app/reducer';
import Claim from './claim/reducer';
import PendingClaims from './pendingclaim/reducer';
import Mails from './mail/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    Auth,
    App,
    Claim,
    PendingClaims,
    Mails,
  });
