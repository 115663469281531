//https://ionicons.com/usage#md-briefcase
const options = [
  {
    key: 'register',
    label: 'sidebar.Register',
    leftIcon: 'ion-android-add',
  },
  {
    key: 'evaluate',
    label: 'sidebar.Evaluate',
    leftIcon: 'ion-calculator',
  },
  {
    key: 'decision',
    label: 'sidebar.Decision',
    leftIcon: 'ion-android-checkbox-outline',
  },
  {
    key: 'report',
    label: 'sidebar.Report',
    leftIcon: 'ion-ios-paper',
  },
  {
    key: 'payment',
    label: 'sidebar.Payment',
    leftIcon: 'ion-cash',
  },
  {
    key: 'search',
    label: 'sidebar.Search',
    leftIcon: 'ion-search',
  },
  {
    key: 'review',
    label: 'sidebar.Review',
    leftIcon: 'ion-search',
  },
];
export default options;
