import log from 'loglevel';
import SuperFetch from './superFetch';
import moment from 'moment';
//MAR-24-2021 - RUSHIKESH K - https://alliancedev.atlassian.net/browse/CT-240

//Log - https://github.com/pimterry/loglevel
//Send logs to remote server - https://github.com/kutuluk/loglevel-plugin-remote

/* Examples :
log.trace(msg)
log.debug(msg)
log.info(msg)
log.warn(msg)
log.error(msg) */

/* const defaults = {
    url: '/logger',
    method: 'POST',
    headers: {},
    token: '',
    onUnauthorized: failedToken => {},
    timeout: 0,
    interval: 1000,
    level: 'trace',
    backoff: {
      multiplier: 2,
      jitter: 0.1,
      limit: 30000,
    },
    capacity: 500,
    stacktrace: {
      levels: ['trace', 'warn', 'error'],
      depth: 3,
      excess: 0,
    },
    timestamp: () => new Date().toISOString(),
    format: remote.plain,
  }; */
/* 
const apiPath = `${jwtConfig.fetchUrl}logs`; */

/* const format = log => ({
  level: log.level.label,
  logger: log.logger,
  message: log.message,
  stackTrace: log.stacktrace,
}); */

/* remote.apply(log, {
  url: apiPath,
  stacktrace: {
    depth: 10,
  },
}); */

/* remote.apply(log, defaults);
log.enableAll(); */
//log.disableAll();

const sendLogToApi = logText => {
  SuperFetch.post('logs', { logText: logText }).catch(e => console.log(e));
};

export function sendLog(logText) {
  console.log(logText);
  sendLogToApi(moment().format('YYYY-MM-DD HH:mm:ss') + ' : ' + logText);
}
