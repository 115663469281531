import { all, takeEvery, put, call, fork } from 'redux-saga/effects';

import SuperFetch from '../../helpers/superFetch';
import SuperFetchFile from '../../helpers/superFetchFile';
import notification from '@isomorphic/shared/isomorphic/components/Notification';
import actions from './actions';
import base64url from 'base64url';

export function* initClaimInfo() {
  yield takeEvery('INITCLAIMINFO_REQUEST', function*({ payload }) {
    const { history, values } = payload;
    const result = yield call(SuperFetch.post, 'claim/claim', values);

    if (result.status === 200) {
      yield put({
        type: actions.INITCLAIMINFO_SUCCESS,
        payload: result.data,
      });
      history.push('/dashboard/register/' + base64url(result.data.id));
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* getClaimByParam() {
  yield takeEvery('GETCLAIMBYPARAM_REQUEST', function*({ payload }) {
    const result = yield call(SuperFetch.get, 'claim/claim', payload);
    if (result.status === 200) {
      yield put({
        type: actions.GETCLAIMBYPARAM_SUCCESS,
        payload: result.data,
      });
    } else {
      notification(
        'error',
        'Something wrong in getting claim by params' || result
      );
    }
  });
}

export function* updateClaimInfo() {
  yield takeEvery('UPDATECLAIMINFO_REQUEST', function*({ payload }) {
    const result = yield call(SuperFetch.put, 'claim/claim', payload);
    console.log('ohhhhhhhhhhhh');
    if (result.status === 200) {
      yield put({
        type: actions.CREATECLAIM_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* updateReimbusementCurrency() {
  yield takeEvery('UPDATEREIMBUSEMENTCURRENCY_REQUEST', function*({ payload }) {
    const result = yield call(
      SuperFetch.post,
      'claim/updateReimbusementCurrency',
      payload
    );

    if (result.status === 200) {
      yield put({
        type: actions.UPDATEREIMBUSEMENTCURRENCY_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* createVisit() {
  yield takeEvery('CREATEVISIT_REQUEST', function*({ payload }) {
    const result = yield call(SuperFetch.post, 'claim/visit', payload);

    if (result.status === 200) {
      yield put({
        type: actions.CREATEVISIT_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* updateVisit() {
  yield takeEvery('UPDATEVISIT_REQUEST', function*({ payload }) {
    //console.log('UPDATEVISIT_REQUEST')
    const result = yield call(SuperFetch.put, 'claim/visit', payload);
    //console.log(result)
    if (result.status === 200) {
      //console.log('saga result',result.data)
      yield put({
        type: actions.UPDATEVISIT_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* deleteVisit() {
  yield takeEvery('DELETEVISIT_REQUEST', function*({ payload }) {
    //console.log('UPDATEVISIT_REQUEST')
    const result = yield call(SuperFetch.delete, 'claim/visit', payload);
    //console.log(result)
    if (result.status === 200) {
      //console.log('saga result',result.data)
      yield put({
        type: actions.DELETEVISIT_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* updateBillingCurrency() {
  yield takeEvery('UPDATEBILLINGCURRENCY_REQUEST', function*({ payload }) {
    const result = yield call(
      SuperFetch.post,
      'claim/updateBillingCurrency',
      payload
    );
    if (result.status === 200) {
      notification(
        'success',
        'update Billing Currency Successfully!' || result
      );
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* updateBillingInfo() {
  yield takeEvery('UPDATEBILLINGINFO_REQUEST', function*({ payload }) {
    const result = yield call(
      SuperFetch.post,
      'claim/uploadBillingInfo',
      payload
    );
    if (result.status === 200) {
      notification('success', 'update Billing Number Successfully!' || result);
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* uploadBillingInfoOtherName() {
  yield takeEvery('UPLOADBILLINGINFOOTHERNAME', function*({ payload }) {
    const result = yield call(
      SuperFetch.post,
      'claim/billingInfoOther',
      payload
    );
    if (result.status === 200) {
      notification('success', 'updating name' || result);
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}
export function* insertDocuments() {
  yield takeEvery('INSERTDOCUMENTS_REQUEST', function*({ payload }) {
    const result = yield call(
      SuperFetchFile.post,
      'claim/insertdocumentsnotes',
      payload
    );

    if (result === undefined) {
      notification('warning', 'No notes saved!');
    } else if (result.status === 200) {
      notification('success', 'note insert succeed!');
      yield put({
        type: actions.INSERTDOCUMENTS_SUCCESS,
        payload: result,
      });
    }
  });
}

export function* getClaimTitleById() {
  yield takeEvery('GETCLAIMTITLEBYID_REQUEST', function*({ payload }) {
    const result = yield call(SuperFetch.get, 'claim/claimTitle', payload);

    if (result.status === 200) {
      yield put({
        type: actions.GETCLAIMTITLEBYID_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('warning', 'Some record is wrong!');
    }
  });
}
//
// export function* initVisitsDataById() {
//   yield takeEvery('INITVISITSDATABYID_REQUEST', function*({ payload }) {
//
//     const result = yield call(SuperFetch.post,'claim/getVisitsById',{claimID:payload});
//
//     if (result.status===200) {
//       yield put({
//         type: actions.INITVISITSDATABYID_SUCCESS,
//         payload: result.data,
//       });
//     } else {
//       notification('warning', "Some record is wrong!")
//     }
//   });
// }

export function* getAllClaims() {
  yield takeEvery('GETALLCLAIMS_REQUEST', function*({ payload }) {
    const result = yield call(SuperFetch.get, 'claim/allClaim');
    if (!result.data.error) {
      yield put({
        type: actions.GETALLCLAIMS_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('error', 'something Wrong' || result);
    }
  });
}

export function* submitClaimInfo() {
  yield takeEvery('SUBMITCLAIMINFO_REQUEST', function*({ payload }) {
    const result = yield call(SuperFetch.put, 'claim/rate', payload);

    if (result.status === 200) {
      yield put({
        type: actions.SUBMITCLAIMINFO_SUCCESS,
        payload: result.data,
      });
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export function* submitDocInfo() {
  yield takeEvery('SUBMITDOCINFO_REQUEST', function*({ payload }) {
    const { value } = payload;
    const result = yield call(SuperFetch.put, 'claim/claim', value);
    if (result.status === 200) {
      yield put({
        type: actions.RESETCLAIM,
      });
    } else {
      notification('error', 'Something goes wrong' || result);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(initClaimInfo),
    fork(getClaimByParam),
    fork(updateClaimInfo),
    fork(updateReimbusementCurrency),
    fork(createVisit),
    fork(updateVisit),
    fork(deleteVisit),
    fork(updateBillingInfo),
    fork(updateBillingCurrency),
    fork(uploadBillingInfoOtherName),
    fork(insertDocuments),
    fork(getAllClaims),
    fork(getClaimTitleById),
    fork(submitClaimInfo),
    fork(submitDocInfo),
  ]);
}
