import { jwtConfig } from '../settings';
import axios from 'axios';

const customHeader = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: localStorage.getItem('id_token') || undefined,
});

const baseAxios = (method, url, data = {}) => {
  var arg = {
    method: method,
    url: `${jwtConfig.fetchUrl}${url}`,
    headers: customHeader(),
    data: JSON.stringify(data),
  }

  switch(method) {
    case 'get':
      arg.params = data
      break;
    case 'post':
      arg.data = JSON.stringify(data)
      break;
    case 'put':
      arg.params = data
      break;
    case 'delete':
      arg.params = data
      break;
    default:
      arg.data = JSON.stringify(data)
      // code block
  }

  return axios(arg)
    .then(res => res)
    .catch(error => ({ error: error }));

};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = baseAxios.bind(null, method);
});
export default SuperFetch;
