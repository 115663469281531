const actions = {
  STATUSPENDINGACTION_CLAIMS: 'STATUSPENDINGACTION_CLAIMS',
  SETPENDINGACTION_CLAIMS: 'SETPENDINGACTION_CLAIMS',

  setPendingActionOnClaims: payload => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.SETPENDINGACTION_CLAIMS,
        payload,
      });
    };
  },
};
export default actions;
