import React from 'react';
import { useState, useEffect } from 'react';
import history from './auth0/history';

const Timer = props => {
  const { initialSeconds = 0 } = props;
  const [seconds, setSeconds] = useState(initialSeconds);
  const pendingTimerEndTime = localStorage.getItem('pendingTimerEndTime');

  useEffect(() => {
    var interval = 0;

    if (pendingTimerEndTime && props.visible === 'block') {
      const dt = new Date(pendingTimerEndTime);
      if (new Date() > dt) {
        console.log('Stop Timer');
        console.log(history);
        if (history) {
          history.push('/dashboard');
        }
        setSeconds(0);
      } else {
        interval = (dt.getTime() - new Date().getTime()) / 1000;
        if (interval > 0) setSeconds(interval);
      }
    }

    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {seconds === 0 ? null : (
        <div>
          {/*  {' '} */}
          {/* {seconds < 10 ? `0${seconds}` : seconds} */}
        </div>
      )}
    </div>
  );
};

export default Timer;
