import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Popover } from 'antd';
import appActions from '../../redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import Timer from '../../helpers/timer';

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];
const pendingTimerEndTime = localStorage.getItem('pendingTimerEndTime');

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PendingClaims: props.PendingClaims.status,
    };
  }

  showTimeoutIcon = () => {
    var interval = 0;

    if (this.state.PendingClaims && pendingTimerEndTime) {
      const dt = new Date(pendingTimerEndTime);
      if (new Date() > dt) {
        return 'none';
      } else {
        interval = (dt.getTime() - new Date().getTime()) / 1000;
        return 'block';
      }
    }

    return this.state.PendingClaims ? 'block' : 'none';
  };

  render() {
    const { toggleCollapsed } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
              }
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>

          <ul className="isoRight">
            <li
              style={{
                justifycontent: 'center',
                display: this.showTimeoutIcon(),
              }}
            >
              <Popover
                content={
                  'You have clients who have not been updated for 48 hours, you must complete your client update(s) to proceed'
                }
                title="Pending Action Alert"
                trigger="hover"
              >
                <i className="ion-ios-timer" />{' '}
              </Popover>
            </li>
            <li
              style={{
                justifycontent: 'center',
                display: this.showTimeoutIcon(),
              }}
            >
              <Timer visible={this.showTimeoutIcon()}></Timer>
            </li>
            <li
              onClick={() => this.setState({ selectedItem: 'user' })}
              className="isoUser"
            >
              <TopbarUser url={this.props.url} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.App, PendingClaims: state.PendingClaims };
}

export default connect(mapStateToProps, { toggleCollapsed })(Topbar);
