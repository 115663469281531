
import actions from './actions';


const initState = {
  claimID: null,
  claimData:{},
  tabIndex:null,
};


export default function claimReducer(state = initState, action) {
  // console.log('action',action.type)
  // console.log(state.claimData)
  switch (action.type) {
    case actions.INITCLAIMINFO_SUCCESS:
      return {
        ...state,
        claimID:action.payload.id,
        claimData:action.payload,
       };
    case actions.CREATECLAIM_SUCCESS:
      return {
        ...state,
        claimID:action.payload.id,
        claimData:action.payload,
       };
    case actions.GETCLAIMBYPARAM_SUCCESS:
      return {
        ...state,
        claimID:action.payload.id,
        claimData: action.payload,
       };
    case actions.GETCLAIMTITLEBYID_SUCCESS:
      return {
       ...state,
       claimID:action.payload.id,
       claimData: action.payload,
      };
    case actions.UPDATEREIMBUSEMENTCURRENCY_SUCCESS:
      return {
        ...state,
        claimID:action.payload.id,
        tabIndex:'DocumentsButton',
      };
    case actions.CREATEVISIT_SUCCESS:
      if(!state.claimData.ClaimInfoVisits)
        state.claimData.ClaimInfoVisits= []
      state.claimData.ClaimInfoVisits.push(action.payload)
      return {
        ...state,
        claimData:state.claimData,
       };
   case actions.UPDATEVISIT_SUCCESS:
     let updateindex = state.claimData.ClaimInfoVisits.findIndex(item => item.id === action.payload.id)
     state.claimData.ClaimInfoVisits.splice(updateindex,1,action.payload)
     return {
       ...state,
       claimData:state.claimData,
      };
    case actions.DELETEVISIT_SUCCESS:
      let deleteindex = state.claimData.ClaimInfoVisits.findIndex(item => item.id === action.payload.id)
      state.claimData.ClaimInfoVisits.splice(deleteindex,1)
      return {
        ...state,
        claimData:state.claimData,
       };
    case actions.RESETCLAIM:
      return {
        claimID: null,
        claimData:{},
        tabIndex:'ClaimsButton',
      };
    case actions.GETALLCLAIMS_SUCCESS:
      return { ...state, evaluate: action.payload };
    case actions.SETTABINDEX_REQUEST:
      return { ...state, tabIndex: action.payload };
    case actions.SUBMITCLAIMINFO_SUCCESS:
      return {
        claimID:action.payload.id,
        claimData:action.payload,
        tabIndex:'BillingButton',
      };
    default:
      return state;
  }
}
